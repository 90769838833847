import tw, { styled, css } from 'twin.macro';
import { AngleDownIcon } from '../../common-components';

export const LanguageSelect = styled.label(() => [
  tw`relative flex flex-row items-center`,
  css`
    .flag {
      ${tw`h-5`}
    }
    .flag {
      padding-left: 16px;
      padding-right: 10px;
    }
    option {
      display: none;
    }
  `,
]);

export const AngleIcon = styled(AngleDownIcon)(() => [
  tw`text-2xl absolute right-0 -top-1 pointer-events-none cursor-pointer mr-[16px]`,
]);

type DropdownProps = { position?: string };

export const Select = styled.select(() => [
  tw`pr-7 h-6 bg-transparent appearance-none font-body focus:ring cursor-pointer w-[75%]`,
]);

export const Dropdown = styled.div<DropdownProps>(({ position }) => [
  css`
    min-width: 225px;
    height: 0;

    .dropbtn {
      padding: 0 16px;
    }
    width: 100%;
    position: relative;

    .chevron {
      position: absolute;
      right: 0;
      top: -5px;
      font-size: 20px;
      color: rgb(97, 97, 97);
    }

    .dropdown-content {
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      width: 100%;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      height: 250px;
      overflow: scroll;

      svg {
        width: 30px;
      }
    }

    .dropdown-content li {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;

      &.active {
        background: rgba(90, 30, 236, 1);
        color: white;
        &:hover {
          cursor: not-allowed;
          background: rgba(90, 30, 236, 1);
          color: white;
        }
      }

      &:hover {
        background: #fafafa;
      }
    }
  `,
  position === 'top' &&
    css`
      .dropdown-content {
        top: -295px;
      }
    `,
]);
