import 'twin.macro';
import LanguageSelect from '../LanguageSelect';
import useFooterContent from './useFooterContent';
import { LegalLinks, LegalDocuments } from './LegalLinks';
import {
  Footer as FooterWrap,
  HeadSection,
  LegalListItem,
  SocialList,
  BodyDiv,
  TailSection,
  LegalList,
  SocialListLink,
  LanguageSelectWrapper,
} from './FooterStyles';
import { Heading8 } from '../../common-components/Headings/Headings';
import { useTranslation } from '../../hooks/useTranslation';
import useLanguage from '../../hooks/useLanguage';
import { downloadCourseOverviewAsExcel } from '../../utils/courseOverviewUtils';

export const Footer = () => {
  const t = useTranslation();
  const {
    language: { code: languageCode },
  } = useLanguage();
  const { categories, socials } = useFooterContent();

  function downloadAsExcel(): void {
    const firstHeaderName = t('ftr_export_excel_table_header');
    const fileName = t('ftr_export_excel_file_name');

    downloadCourseOverviewAsExcel(firstHeaderName, fileName);
  }

  const hasTranslation = (translationKey: string) => {
    const value = t(translationKey, { silent: true });
    return (
      !!value &&
      value !== '...' &&
      value !== translationKey &&
      !value.startsWith('[')
    );
  };

  return (
    <FooterWrap>
      <HeadSection>
        <h1>
          <img
            alt="GoodHabitz, online training"
            src={process.env.PUBLIC_URL + '/img/GH_LOGO_violet.svg'}
            tw="h-[65px] w-auto"
          />
        </h1>
        <SocialList>
          {Object.entries(socials).map(([name, { link, icon }]) => (
            <li key={name}>
              <SocialListLink href={link} target="_blank" rel="noreferrer">
                {icon}
                <span tw="sr-only">{name}</span>
              </SocialListLink>
            </li>
          ))}
        </SocialList>
      </HeadSection>

      <BodyDiv>
        {Object.entries(categories).map(
          ([categoryName, { translationKey, links }]) => (
            <section key={categoryName} tw="min-w-[150px] last:xl:min-w-min">
              <Heading8 as="h2" tw="text-blue-100 font-bold text-lg">
                {t(translationKey)}
              </Heading8>
              <ul>
                {links
                  .filter(({ translationKey }) =>
                    hasTranslation(translationKey),
                  )
                  .map(
                    ({ link, translationKey: linkTranslationKey }, index) => (
                      <li key={index} tw="mt-4">
                        <a
                          href={link}
                          tw="text-base no-underline hover:underline"
                        >
                          {t(linkTranslationKey)}
                        </a>
                      </li>
                    ),
                  )}
              </ul>
            </section>
          ),
        )}
      </BodyDiv>

      <TailSection>
        <LegalList>
          <LegalLinks />
          <LegalListItem>
            <a
              href={'/' + languageCode + '/cookies/cookie-settings.html'}
              tw="no-underline"
            >
              {t('ftr_gdpr_link_settings')}
            </a>
          </LegalListItem>
          <LegalDocuments />
          <LegalListItem>
            <button
              onClick={downloadAsExcel}
              tw="inline-block font-body text-[#46403d] text-sm cursor-pointer no-underline hover:underline"
            >
              {t('ftr_export_excel_link')}
            </button>
          </LegalListItem>
        </LegalList>

        <LanguageSelectWrapper>
          <LanguageSelect position="top" />
        </LanguageSelectWrapper>
      </TailSection>
    </FooterWrap>
  );
};
