import React, { useEffect, useMemo, useState } from 'react';

import useLanguage from '../../hooks/useLanguage';
import useEntitlements, {
  type EntitlementName,
} from '../../hooks/useEntitlements';
import { useWebsession } from '../../hooks/useWebsession';
import { LanguageCode } from '../../models/Language';
import { setCookie } from '../../vanilla-js/common';
import { CountryFlag } from '../CountryFlag';
import * as Styled from './LanguageSelect.styles';

export default function LanguageSelect({
  className,
  origin,
  menuLanguageChange,
  onChange,
  name,
  value,
  position,
}: {
  className?: string;
  origin?: string;
  menuLanguageChange?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  name?: string;
  value?: LanguageCode;
  position?: string;
  navigateOnChange?: boolean;
}) {
  const {
    language: { code },
  } = useLanguage();

  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [currentLanguageCode, setCurrentLanguageCode] = useState<LanguageCode>(
    value || code,
  );

  useEffect(() => {
    if (!value) {
      setCurrentLanguageCode(code);
    }
  }, [code, value]);

  const [languageList] = useWebsession('LanguageList');
  const [currentUser] = useWebsession('CurrentUser');
  const entitlements = useEntitlements();

  function getEntitlementNameByLanguageCode(languageCode: string) {
    const transformedLangaugeCode = languageCode
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
    return `entLanguage${transformedLangaugeCode}` as EntitlementName;
  }
  const availableLanguages = useMemo(() => {
    if (!languageList) return [];
    const formattedLanguageList = languageList
      .filter(({ StatusId }) => StatusId === 1)
      .map((languageItem) => ({
        code: languageItem.LanguageRegion as LanguageCode,
        name: languageItem.LanguageName,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const isLoggedIn =
      currentUser && currentUser.Id !== -1 && currentUser.LoginOrigin;

    return isLoggedIn
      ? formattedLanguageList.filter(
          (language) =>
            entitlements[getEntitlementNameByLanguageCode(language.code)] ===
            true,
        )
      : formattedLanguageList;
  }, [languageList, entitlements, currentUser]);

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange && menuLanguageChange === false) {
      setOpenDropdown(false);
      return;
    }
    const newCode = event.target.value;
    setCookie('language', newCode, 365);
    const newHref = window.location.href.replace(code, newCode);
    if (window.location.href === newHref) {
      throw new Error(
        `Could not change to language ${newCode} since current path does not include code ${code}.`,
      );
    }
    if (menuLanguageChange) {
      window.location.href =
        window.location.origin + '/' + newCode + '/personal-courses.html';
    } else {
      window.location.href = newHref;
    }
  };

  const updateSelect = (languageCode: LanguageCode) => {
    setCurrentLanguageCode(languageCode);

    if (origin) {
      if (document.getElementById('lang-select-' + origin)) {
        (
          document.getElementById('lang-select-' + origin) as HTMLSelectElement
        ).value = languageCode;
      }
    } else {
      if (document.getElementById('lang-select')) {
        (document.getElementById('lang-select') as HTMLSelectElement).value =
          languageCode;
      }
    }

    var evObj = document.createEvent('HTMLEvents');
    evObj.initEvent('change', true, true);
    var elem2 = document.getElementById('lang-select-' + origin);
    elem2?.dispatchEvent(evObj);
    if (menuLanguageChange !== false) {
      var elem = document.getElementById('lang-select');
      elem!.dispatchEvent(evObj);
    }
  };

  return availableLanguages.length <= 1 ? (
    <Styled.LanguageSelect>
      <CountryFlag languageRegionCode={currentLanguageCode} /> &nbsp;
      {
        languageList?.find(
          (lang) => lang.LanguageRegion === currentLanguageCode,
        )?.LanguageName
      }
    </Styled.LanguageSelect>
  ) : (
    <>
      <Styled.LanguageSelect className={className}>
        <CountryFlag languageRegionCode={currentLanguageCode} /> &nbsp;
        <Styled.Select
          id={origin ? 'lang-select-' + origin : 'lang-select'}
          onChange={onChange || changeLanguage}
          value={currentLanguageCode}
          name={name}
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          {availableLanguages.map(
            ({ code: languageCode, name: languageName }) => (
              <option value={languageCode} key={languageCode}>
                {languageName}
              </option>
            ),
          )}
        </Styled.Select>
        <Styled.AngleIcon />
      </Styled.LanguageSelect>
      {openDropdown && (
        <Styled.Dropdown position={position}>
          <ul id="myDropdown" className="dropdown-content">
            {availableLanguages.map(
              ({ code: languageCode, name: languageName }) => (
                <li
                  value={languageCode}
                  title={languageName}
                  onClick={() => updateSelect(languageCode)}
                  key={languageCode}
                  className={languageCode === (value || code) ? 'active' : ''}
                >
                  <CountryFlag languageRegionCode={languageCode} />
                  {languageName}
                </li>
              ),
            )}
          </ul>
        </Styled.Dropdown>
      )}
    </>
  );
}
