import tw from 'twin.macro';
import { Button } from '../Button/Button';
import { Modal, ModalActions } from '../Modal';
import DialogIconFail from '../../assets/dialog_icon_fail.gif';

const StyledTextContainer = tw.div`leading-6 pb-3 pt-7 px-8 text-center`;
const StyledImage = tw.img`h-[160px] w-fit mx-auto`;

type Props = {
  isOpen: boolean;
  children: React.ReactNode | null;
  closeButtonLabel: string;
  onClose: () => void;
};

export const AlertModal = ({
  isOpen,
  children,
  closeButtonLabel = 'Close',
  onClose,
}: Props) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} width="20rem" bgColor="purple">
      <StyledImage src={DialogIconFail} alt="Failed action" />
      <StyledTextContainer>{children}</StyledTextContainer>
      <ModalActions align="center">
        <Button onClick={onClose}>{closeButtonLabel}</Button>
      </ModalActions>
    </Modal>
  );
};
