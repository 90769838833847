import tw, { css, styled } from 'twin.macro';
import { Heading4, Modal } from '../../../common-components';

export const LoginContainer = styled.main(() => [
  tw`min-h-screen bg-center bg-no-repeat bg-cover flex flex-col justify-center items-center `,
  css`
    background-image: url('/img/large-bg_final_compressed.jpg');
  `,
]);

export const LoginFormContainer = tw.div`rounded-large bg-grey-0 p-6 md:p-10 flex flex-col gap-6 relative`;

export const LoginForm = tw.form`flex flex-col gap-6 relative`;

export const LanguageSelectLabel = tw.label`sr-only`;

export const ForgotLink = tw.a`mx-auto`;

export const LoginHeading4 = tw(Heading4)`whitespace-nowrap `;

export const LoadingModal = styled(Modal)`p-5`;

export const PasswordContainer = styled.div(() => [
  tw`grid grid-cols-[10fr 1fr] grid-rows-[1fr 2fr] `,
  css`
    label {
      grid-column: 1/3;
      grid-row: 1/3;
    }
    .showhide {
      grid-column: 2/3;
      grid-row: 2/3;
      display: flex;
      align-items: center;
    }
  `,
]);
